<template>
    <v-timeline
            class="user_add_form"
            align-top
            dense>
        <v-timeline-item
                color="red"
                small>
            <v-layout pt-3>
                <v-flex>
                    <strong>Personal Information</strong>
                    <div class="caption">Select type of staff.</div>
                    <v-flex xs12>
                        <v-flex pt-3 xs12>
                            <v-container style="padding:0" grid-list-md>
                                <v-layout row wrap>
                                    <v-flex sm3 xs12>
                                        <small class="input-label">First Name.</small>
                                        <h4>{{user.fname || '-'}}</h4>
                                    </v-flex>
                                    <v-flex sm3 xs12>
                                        <small class="input-label">Middle Name.</small>
                                        <h4>{{user.mname || '-'}}</h4>
                                    </v-flex>

                                    <v-flex sm3 xs12>
                                        <small class="input-label">Last Name.</small>
                                        <h4>{{user.lname || '-'}}</h4>
                                    </v-flex>

                                    <v-flex sm3 xs12>
                                        <small class="input-label">Gender.</small>
                                        <h4>{{user.gender || '-'}}</h4>
                                    </v-flex>
                                    <v-flex sm3 xs12>
                                        <small class="input-label">Blood Group.</small>
                                        <h4>{{user.blood_group || '-'}}</h4>
                                    </v-flex>

                                    <v-flex sm3 xs12>
                                        <small class="input-label">Date of birth.</small>
                                        <h4>{{user.dob || '-'}}</h4>
                                    </v-flex>

                                    <v-flex sm3 xs12>
                                        <small class="input-label">Religion.</small>
                                        <h4>{{user.religion || '-'}}</h4>
                                    </v-flex>

                                    <v-flex sm3 xs12>
                                        <small class="input-label">Ethinicity.</small>
                                        <h4>{{user.ethnicity || '-'}}</h4>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-flex>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-timeline-item>
        <v-timeline-item
                color="purple lighten-1"
                small>
            <v-layout pt-3>
                <v-flex>
                    <strong>Address Information</strong>
                    <div class="caption">Primary/Temporary</div>
                    <v-flex pt-3 xs12>
                        <v-container style="padding:0" grid-list-md>

                            <h3>Permanent</h3>
                            <v-layout row wrap>
                                <v-flex xs12 sm3>
                                    <small class="input-label">Province/State</small>
                                    <h4>{{$address.getName('state',user.pstate_id)}}</h4>
                                </v-flex>
                                <v-flex xs12 sm2>
                                    <small class="input-label">District</small>
                                    <h4>{{$address.getName('district',user.pdistrict_id)}}</h4>
                                </v-flex>
                                <v-flex xs12 sm3>
                                    <small class="input-label">Municipality</small>
                                    <h4>{{$address.getName('city',user.pcity_id)}}</h4>
                                </v-flex>

                                <v-flex xs12 sm1>
                                    <small class="input-label">Ward</small>
                                    <h4>{{user.pward_no}}</h4>
                                </v-flex>

                                <v-flex xs12 sm3>
                                    <small class="input-label">Street/Tole</small>
                                    <h4>{{user.pstreet}}</h4>
                                </v-flex>
                            </v-layout>
                            <br>
                            <br>
                            <h3>Temporary</h3>
                            <v-layout row wrap>
                                <v-flex xs12 sm3>
                                    <small class="input-label">Province/State</small>
                                    <h4>{{$address.getName('state',user.tstate_id)}}</h4>
                                </v-flex>
                                <v-flex xs12 sm2>
                                    <small class="input-label">District</small>
                                    <h4>{{$address.getName('district',user.tdistrict_id)}}</h4>
                                </v-flex>
                                <v-flex xs12 sm3>
                                    <small class="input-label">Municipality</small>
                                    <h4>{{$address.getName('city',user.tcity_id)}}</h4>
                                </v-flex>

                                <v-flex xs12 sm1>
                                    <small class="input-label">Ward</small>
                                    <h4>{{user.tward_no}}</h4>
                                </v-flex>

                                <v-flex xs12 sm3>
                                    <small class="input-label">Street/Tole</small>
                                    <h4>{{user.tstreet}}</h4>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-timeline-item>

        <v-timeline-item
                color="green"
                small>
            <v-layout pt-3 pb-5>
                <v-flex>
                    <strong>Contact information</strong>
                    <div class="caption">Email/Mobile</div>
                    <v-flex pt-3 xs12>
                        <v-container style="padding:0" grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs4>
                                    <small class="input-label">Email <span v-if="user.email_private">(Private)</span>
                                    </small>
                                    <h4>{{user.email||'-'}}</h4>
                                </v-flex>
                                <v-flex xs4>
                                    <small class="input-label">Primary Mobile <span
                                            v-if="user.mobile_private">(Private)</span></small>
                                    <h4>{{user.primary_mobile||'-'}}</h4>
                                </v-flex>

                                <v-flex xs4>
                                    <small class="input-label">Phone</small>
                                    <h4>{{user.residence || '-'}}</h4>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-timeline-item>
        <v-timeline-item
                color="yellow"
                small>
            <v-layout pt-3 pb-5>
                <v-flex>
                    <strong>Department information</strong>
                    <v-flex pt-3 xs12>
                        <v-container style="padding:0" grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs4 v-for="(dep,index) in user.departments" :key="index">
                                    <h4>{{dep.title}}</h4>
                                </v-flex>
                            </v-layout>
                        </v-container>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-timeline-item>

    </v-timeline>
</template>

<script>
    export default {
        props: {
            user: {
                required: true,
                type: Object
            }
        },
        data() {
            return {}
        }
    }
</script>
